@import '../../styles/mixins';

$largest-size: 1440px;
$large-size: 1280px;
$medium-size: 1024px;
$small-size: 768px;
$small-mid-size: 667px;
$smallest-size: 480px;
$ultra-smallest-size: 375px;

$transition-slow: 450ms ease;
$transition-base: 300ms ease;

$font-size-base: 16;
$font-family-base: 'Open Sans', sans-serif;
$font-family-solid: 'Montserrat', sans-serif;
$font-family-italic: 'Libre Baskerville', sans-serif;

$color-blue: #006CA9;
$color-orange: $color-blue;
$color-orange-dark: $color-blue;
$color-orange-1: #FF6B58;
//$color-orange-dark: #c94a24;

$color-laguna: #00A3AF;
$color-laguna-light: #F2FAFB;
$color-laguna-dark: #007178;
//$color-laguna-dark: #00ADB5;

$color-grey-light: #adadad;
$color-grey: #676767;

$primary-1: #ae0032;
$primary-2: #455162;
$primary-3: #1f2f46;

$neutral-1: #0d1720;
$neutral-2: #636363;
$neutral-3: #dadada;
$neutral-4: #f2f2f2;
$neutral-5: #fcfbfa;

.blog-popular {
    overflow: hidden;
}
.blog-popular__container {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 24px;
    grid-column-gap: 24px;

    padding-bottom: 60px;

    @media (min-width: $small-mid-size) {
        grid-template-columns: 1fr 1fr;
        align-items: center;
    }
    @media (min-width: $small-size) {
        padding-bottom: 80px;
    }
    @media (min-width: $medium-size) {
        grid-column-gap: 32px;
        grid-row-gap: 40px;
        padding-bottom: 100px;
    }
}
.blog-popular__video {
    @include video-wrap;
    @include aspect-ratio-w-pseudo(7, 4);
}
.blog-popular__wr-img {
    display: inline-block;

    width: 100%;

    position: relative;
    
    @include aspect-ratio-w-pseudo(3, 2);
}
.blog-popular__img {
    img {
        min-width: 100%;
        min-height: 100%;
        max-width: 100%;
        max-height: 100%;
        
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        object-fit: cover;
        object-position: center;
    }
}
.blog-popular__ico {
    width: 23px;
    height: 20px;

    position: absolute;
    bottom: 23px;
    left: 21px;
}
.blog-popular__sup-heading {
    margin-bottom: 5px;
}
.blog-popular__heading {
    max-width: 415px;

    a {
        color: inherit;
        text-decoration: none;
        
        transition: 300ms ease-in-out;

        @media (hover: hover) {
            &:hover {
                color: $color-orange;
            }
        }
    }
}
.blog-popular__heading-link {
    display: inline-block;

    text-decoration: none;
    color: #222222;

    & > * {
        transition: 300ms ease-in-out;
    }

    @media (hover: hover) {
        &:hover {
            & > * {
                color: $color-orange;
            }
        }
    }
}
.blog-popular__stat-list {
    display: flex;
    flex-wrap: wrap;

    margin-top: 5px;
}
.blog-popular__stat-item {
    &:not(:last-child) {
        margin-right: 20px;
    }
    &_mobile-hidden {
        display: none;

        @media (min-width: $smallest-size) {
            display: block;
        }
    }
}
.blog-popular__stat-val {
    display: inline-block;

    &:last-child:not(:first-child) {
        margin-left: 4px;
    }
    &:first-child:not(:last-child) {
        margin-right: 4px;
    }
}

.blog-popular__text-content {
    margin-top: 10px;
    max-width: 490px;

    p {
        &:not(:last-child) {
            margin-bottom: 24px;
        }
    }
}
.blog-popular__row-head {
    @media (min-width: 667px) {
        grid-column: 1 / 2 span;
    }
}
.blog-popular__head-heading {
    margin-bottom: 20px;
}
.blog-popular__head-text {
    max-width: 685px;

    p {
        &:not(:last-child) {
            margin-bottom: 24px;
        }
    }
}